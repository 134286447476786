import base64 from 'base-64';


const TOKEN = "QLKvDc"
const KLAVIYO_URL = "https://a.klaviyo.com/api/track?data="

export function sendKlaviyoEvent(eventName, userEmail) {
	

   let data = {
   'token': TOKEN,
   'event': eventName,
   'customer_properties' : {
    "$email" : userEmail
  		}
 	};

 	let encodedData = base64.encode(JSON.stringify(data))

 	//console.log(data)


 	fetch(KLAVIYO_URL+encodedData )
      .then(function (response) {
        console.log(response)
      })
      .then((data) => {
      	//console.log(data)
      })
      .catch(err => console.log('Error with Klaviyo', err));

}