import React, { useState, useEffect } from 'react';
import store from 'store';


export default function DesktopIcon(props) {
    
    const [icon, setIcon] = useState(props.icon);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => { 

	    if(props.currentEpisode && icon.parent === -1) {
	    	
	    	const hasBeenOpen = store.get("BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+icon.id)
    
    		
		    if (typeof hasBeenOpen === 'undefined') {
		    	const icons = props.icons
		    	if(props.currentEpisode === icon.episode) {
		    		setIsNew(true)
		    	}else{
		    		//console.log(icons)
		    		icons.forEach(function(i){
		    			
		    			if(i.parent === icon.id && i.episode === props.currentEpisode) {
		    				setIsNew(true)
		    				return
		    			}
		    		})
		    	}

		    }
	    	
	    }


	    window.addEventListener('UPDATEFOLDER', function (e) { 
        	
        		if(props.currentEpisode && icon.parent === -1) {
			    	const hasBeenOpen = store.get("BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+icon.id)

				    if (typeof hasBeenOpen === 'undefined') {
				    	const icons = props.icons
				    	if(props.currentEpisode === icon.episode) {
				    		setIsNew(true)
				    	}else{
				    		//console.log(icons)
				    		icons.forEach(function(i){
				    			
				    			if(i.parent === icon.id && i.episode === props.currentEpisode) {
				    				setIsNew(true)
				    				return
				    			}
				    		})
				    	}

				    }else{
				    	setIsNew(false)
				    }
			    	
			    }



       }, false);


	   
	}, [isNew, icon])


	const clickIcon = (e) => {

		if(isNew) {
			store.set("BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+icon.id, true);
			setIsNew(false)


			var event = new CustomEvent('UPDATEFOLDER', {detail :  { "folder":"BW0"+props.currentSeason+"0"+props.currentEpisode+"folderID"+icon.id} });
            window.dispatchEvent(event);
		}


		props.iconSingleClick(e)
	}


    
   	
    
    return (
        <div className="icon-wrapper" id={`icon-${icon.id}`} onClick={clickIcon}>
        	{isNew && (<img src="https://cdn.huntakiller.com/blair-witch/s1/exclamation-bubble.svg" className="exclamation-bubble" id={`icon-${icon.id}`} />)}
           
            <img src={"https://cdn.huntakiller.com/blair-witch/"+icon.icon}  className=" icon-image" alt={icon.name} id={`icon-${icon.id}`}/>
        	
        </div>
    )
    
}
