var forgotPasswordAnswers = {
  1: {"correct":[ "rabbit", "bunny", "hare"], "question":"He won her this creature, which proved to be the sum of its parts."},
  2: {"correct":[ "charm","my charm"], "question":"She took this name upon herself in secret, and it only proved her hubris."},
  3: {"correct":[ "rh", "r+h"], "question":"Her second child was mine from the moment she shuddered while her own bleeding heart loomed above her, emblazoned with these letters."},
  4: {"correct":[ "rest","to rest", "time to rest"], "question":"Her firstborn whispered hoarse and barely heard, telling her this time, the only time in a timeless place."},
  5: {"correct":[ "me"], "question":"The truth has been spelled out by the one who carves. He is finished, but I am not. Tell me, detective, on whom my sights are fixed."}
 
};




export default forgotPasswordAnswers = forgotPasswordAnswers;