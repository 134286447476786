import React, { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import store from 'store';
import { useHistory } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import GlitchFx from 'react-glitch-fx/lib/GlitchFx';

import Inbox from './InboxMOM'
import Dock from '../components/Dock'
import Modal from '../components/Modal'
import DesktopIcon from '../components/DesktopIcon'
import MobileMenu from '../components/MobileMenu'
import LockedVideo from  '../components/LockedVideo/LockedVideo'

import PASSWORDS from '../datas/passwords.js';
import ICONS from '../datas/icons.js';
import BLOG_ENTRIES  from '../datas/blogEntries.js';
import { getGetChildrenFromFolder } from '../utils/utils.js';

export default function EpisodeView() {
  

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [showInbox, setShowInbox] = useState(false)
  const [icons, setIcons] = useState([])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [currentBlogEntry, setCurrentBlogEntry] = useState(0)

  const [modals, setModals] = useState([])
  const [isHacked, setIsHacked] = useState(false)

  const [lightBoxImage, setLightBoxImage] = useState(null)
  const [showLockedVideo, setShowLockedVideo] = useState(false)

  useEffect(() => { 
    //console.log("useEffect")
    //console.log("useEffect EpisodeView")

    const passwordStorage = store.get("passwordBW")
    
    
    if (!(typeof passwordStorage === 'undefined')) {
      setPassword(passwordStorage)

      // console.log(PASSWORDS[passwordStorage].episode);

      //console.log(ICONS)
      if(PASSWORDS[passwordStorage].episode>=5){
        setIsHacked(true)
      } 

      let episodeIcons = ICONS.filter(function (icon) {
          return  ((icon.parent === -1) &&(icon.episode <= PASSWORDS[passwordStorage].episode) && (icon.season <= PASSWORDS[passwordStorage].season ))
      });
      setIcons([...episodeIcons]);
      setCurrentBlogEntry(PASSWORDS[passwordStorage].episode)

      function handleResize() {
        if(parseInt(window.innerWidth) >= 800 && mobileMenuOpen){
          
          setMobileMenuOpen(false)

        }
      }


       window.addEventListener("resize", handleResize);


       // Remove event listener on cleanup
       return () => window.removeEventListener("resize", handleResize);


    }else{
      history.push("/")
    }

  }, [history, mobileMenuOpen])



  const openInbox = e => {


    setShowInbox(true)

  }


  const closeInbox = e => {


    setShowInbox(false)

  }

  const openMenu = e => {

    setMobileMenuOpen(true)

  }


  const closeMenu = e => {

    setMobileMenuOpen(false)

  }

  const handleTapBackLockedVideo = e => {
      if(e === "closeVideo"){
        setShowLockedVideo(false)
        return
      }
      if ( ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" overlaypw ") > -1) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-folderlogin ") > -1 ) ) {
        e.preventDefault()  
        setShowLockedVideo(false)
      }

  }




  const handleClickIcon = e => {
       var iconIndex =  e.target.id.substring(5, e.target.id.length);

       

       var iconId =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconId)



       let icon = ICONS[iconId]
       for( var i = 0; i < icons.length; i++){ 
          if ( parseInt(iconId) ===  icons[i].id) {
            icon = icons[i]
            //console.log(icon)
          }
        }


       if(icon.type === "external_url"){
        window.open(icon.url, "_blank")
        return

       }else if(icon.type === "lockedVideoBWs1"){
          //console.log("lockedVideoBWs1")
          setShowLockedVideo(true)
          
       }else {
          let copy_modals = [...modals];

          
          let isPresent=false
          for( var k = 0; k < copy_modals.length; k++){ 
            if ( copy_modals[k].id === icon.id) {
              isPresent = true
              let z = getMaxZModal(copy_modals)
              copy_modals[k].z = z+1;
            }
          }
          if(!isPresent){
            
            if (typeof icon.passwordprotected !== 'undefined' && store.get(icon.name) !== icon.password){
                  

                  //console.log("LALAL")

                  /*this.setState({showPwModal:true});
                  this.setState({selectedIconPw:icon})*/
                  
                  //return
            }


            var childrensIcon = getGetChildrenFromFolder(icon.id, ICONS)


            // if the new modal is from another modal, replace the parent modal w new modal
            if(icon.parent > -1 && icon.type==="folder") {
                //console.log("REPLACEMODAL")
                for( var j = 0; j < copy_modals.length; j++){ 
                  if(copy_modals[j].id === copy_modals.parent){
                    copy_modals[j].name = icon.name
                    
                    copy_modals[j].icons = childrensIcon
                    copy_modals[j].id = icon.id
                  }

                }

            }else{
                var width = 850
                var height = 525
                if (typeof icon.width !== 'undefined'){
                  width = icon.width
                }
                if (typeof icon.height !== 'undefined'){
                  height = icon.height
                }
                let left = (window.innerWidth - parseInt(width))/2
                let top = (window.innerHeight - parseInt(height))/2
                let z = getMaxZModal(modals) 
                if(modals.length >= 1){
                  left = 50 + copy_modals[modals.length-1].left
                  top = 55 + copy_modals[modals.length-1].top
                }
                var modal = {"z":z+1,"type":icon.type, name:icon.name, icons:childrensIcon, id:icon.id, width:icon.width, height:icon.height, left:left, top:top, image_url:icon.imageURL, audio_url:icon.audioURL};
                copy_modals = copy_modals.concat(modal);
            }

          }

          //console.log(copy_modals)

          setModals(copy_modals);
       }


  }

  const handleSetTimerLogout = () => {
    //console.log("handleSetTimerLogout")
    setTimeout(() => {
      store.remove('passwordBW')
      history.push("/")
    }, 5000);



  }


  const handleCloseModal = dataModal => {
    var modalId =  dataModal.id;
    //console.log(modalId)
    var arr = [...modals];

    for( var i = 0; i < arr.length; i++){ 
       if ( arr[i].id === modalId) {
         arr.splice(i, 1); 
       }
    }
    //console.log(arr)
    setModals(arr);
    
    //console.log(this.state)
   }

   const handleUpdateZindex = dataModal => {
    //console.log("handleUpdateZindex")
      var modalId =  dataModal.id;
      var arr = [...modals];
      for( var i = 0; i < arr.length; i++){ 
         if ( arr[i].id === modalId) {
           let z = getMaxZModal(arr) 
           arr[i].z = z+1
         }
      }
      //console.log(arr)
      setModals(arr);


   }

   const getMaxZModal = (modals) => {
    if(modals.length === 0) return 0
    return modals.reduce((max, b) => Math.max(max, b.z), modals[0].z);
  }

  const clickPastBlogEntries = () => {

    setCurrentBlogEntry(currentBlogEntry - 1)
    document.getElementById("blogcontent").scrollTo({
        behavior: "auto",
        top:  0
      });


  }

  const clickNewBlogEntries = () => {
    setCurrentBlogEntry(currentBlogEntry + 1)
    document.getElementById("blogcontent").scrollTo({
        behavior: "auto",
        top:  0
      });
  }

  const openLighbox = (imageUrl) => {
    console.log(imageUrl)
    setLightBoxImage(imageUrl)

  }

  const iconviews = icons.map((icon, index) => {
    return (
      <DesktopIcon icon={icon} key={index} iconSingleClick={handleClickIcon.bind(this)}  icons={ICONS} currentSeason={PASSWORDS[password].season} currentEpisode={PASSWORDS[password].episode}/> 
    )
  })


  const modalviews = modals.map((modal, index) => {
        
      return (
        <Modal modal={modal} key={modal.id} currentEpisode={PASSWORDS[password].episode} closeModal={handleCloseModal} updateZindex={handleUpdateZindex}  iconSingleClick={handleClickIcon.bind(this)}/> 
      )
      
  })

  const currentBlogEntries = (currentBlogEntry > 0) ? BLOG_ENTRIES[currentBlogEntry].map((blog_entry, index) => {
    var imagesString;
    if(blog_entry.images) {
        imagesString = blog_entry.images.map((imageUrl, index) => {
          return (<img src={imageUrl} key={imageUrl} alt={imageUrl} onClick={() => openLighbox(imageUrl)}/>)
       })

   }
      return (
        <div className="blog_entry" key={"blog_entry_"+blog_entry.id}><div className="blog_title" dangerouslySetInnerHTML={ {__html: blog_entry.title} }></div><div className="blog_text" dangerouslySetInnerHTML={ {__html: blog_entry.content} }></div>{imagesString}</div>
      )
      
  }) : "";


  
  return (<>
    <Div100vh  dontresize="true"  className="app blair-witch-dekstop">

      <div className="flex-1 full-height">
          <div className="top-bar-menu">
            <div className="mobile-menu-icon"  onClick={openMenu}>

              <div></div>
              <div></div>
              <div></div>


            </div>
            

            <h1>{isHacked ? "F̛̤̭̙̈̏͒͢Ḯ̛̝̠͍͆̕ͅND̡̘̘͈̾͂̇͡ ͉͡L̡̠͉͓̓́̔̚Į̳̝͂̄̊A͔̗̗̎͆̅̀ͅḾ̻̩͆̓͟ ̯̗̓̂K̢͔̣͇̆̽̔̄͜͞Ḛ͝N̦̦̈́̎T͇͓͋͋͜͞" : "FIND LIAM KENT"}</h1>
          </div>
          <div className="flex blair-witch-content">
            <div className="user_profile">

            {!isHacked ? (<img className="profile_picture" src="https://cdn.huntakiller.com/blair-witch/s1/liam-profile-picture.jpg" alt="liam profile"/>) : ( <GlitchFx><img className="profile_picture" src="https://cdn.huntakiller.com/blair-witch/s1/liam-profile-picture-glitch.jpg" alt="liam profile"/></GlitchFx>)}
              
              <h2>LIAM KENT</h2>
              <p>My name is Rosemary Kent. I’m a resident of Burkittsville, Maryland, and I’m currently in the middle of a personal investigation into the disappearance of my son Liam. If you have any information pertaining to his case, you can use this website to contact me directly. I’m usually very quick to respond to credible evidence.</p>
              <div className="tipline-btn" onClick={openInbox}><div>TIPLINE</div></div>
            </div>
            <div className="blogcontent" id="blogcontent"  >
            
              {currentBlogEntries}

              <div className="blog_nav">


                  {((password !== "") && (currentBlogEntry > 1)) ? (<div className="pastBlogEntryBtn" onClick={clickPastBlogEntries}>&#8592; Past Entries</div>) : (<div></div>) }

                  {((password !== "") && (currentBlogEntry < 6) && (PASSWORDS[password].episode > currentBlogEntry)) ? (<div className="newBlogEntryBtn" onClick={clickNewBlogEntries}>New Entries &#8594;</div>) : (<div></div>) }


              </div>
            </div>
        
            <div className="desktopicons">{iconviews}</div>
        
    </div>
  </div>




      <Dock logout="true" />

      {modalviews}

    </Div100vh> 
    {isHacked && (<div className="glitch-right"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-1"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-2"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
    {isHacked && (<div className="glitch-right-3"><GlitchFx><div className="wierd-glitch-2-right"></div></GlitchFx></div>)}
              

    {(password !== "") && (
    <div style={{display: showInbox  ? 'block' : 'none'}}>
      <Inbox tapBack={closeInbox.bind(this)} currentSeason={PASSWORDS[password].season} currentEpisode={PASSWORDS[password].episode}/>
    </div> )}



    {(password !== "" && mobileMenuOpen === true ) && (
    <div style={{display: mobileMenuOpen  ? 'block' : 'none'}}>
      <MobileMenu tapBack={closeMenu.bind(this)} icons={icons} isHacked={isHacked}  allIcons={ICONS} currentSeason={PASSWORDS[password].season} currentEpisode={PASSWORDS[password].episode} iconSingleClick={handleClickIcon.bind(this)}/>
    </div>
    )}


      {lightBoxImage && (
          <Lightbox
            mainSrc={lightBoxImage}
            onCloseRequest={() => setLightBoxImage(null)}
            
          />
        )}


        {showLockedVideo && (
          <LockedVideo tapBack={handleTapBackLockedVideo.bind(this)} setTimerLogout={handleSetTimerLogout.bind(this)}/>
        )}

        
    </> 
  );
}