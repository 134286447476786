var passwords = {
  BURKITTSVILLE8: {
    season: 1,
    episode: 1,
  },
  QUIETLY5: {
    season: 1,
    episode: 2,
  },
  BRANCHES12: {
    season: 1,
    episode: 3,
  },
  ECHO12: {
    season: 1,
    episode: 4,
  },
  STICKSANDSTONES15: {
    season: 1,
    episode: 5,
  },
  NIGHTFALL1: {
    season: 1,
    episode: 6,
  }
};




export default passwords = passwords;