import React, { Component } from 'react'
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';


import store from 'store';

import EnterEmailView from '../components/Inbox/EnterEmailView'


import { Player } from '../models/player';

import EmailClient from '../components/EmailClient/email-client';


import { sendKlaviyoEvent } from '../utils/klaviyo.js';
//import {getCurrentSubject} from './utils/emailUtils.js';


export default class Inbox extends Component {
    constructor(props) {
        super(props);


        this.currentSeasonIndex = this.props.currentSeason
        this.currentEpisodeIndex = this.props.currentEpisode
        this.subject = ""
        
        const userEmail = store.get("userEmailBW");

        this.state = {
            showEmailClient: true,
            userEmail:userEmail,
        };

    }

    closeModal = (e) => {
        if (typeof document.getElementsByTagName("audio")[0] !== 'undefined') {
            document.getElementsByTagName("audio")[0].pause();
        }
        

        this.props.tapBack()
    };


    closeEmailPopUp = (email) => {
        this.setState({ userEmail:email})
    }

    handleBotEmailResponse = (botResponse) => {
        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        if (correctQuestion !== -1) {
            // Call API
            console.log('Correct');
            sendKlaviyoEvent("BW0"+this.currentSeasonIndex+"0"+this.currentEpisodeIndex+"-complete",this.state.userEmail);

            var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"bw0"+this.currentSeasonIndex, "episode":"0"+this.currentEpisodeIndex, "success": "bw0"+this.currentSeasonIndex+"0"+this.currentEpisodeIndex} });
            window.dispatchEvent(event);
        

        } else {
            console.log('Incorrect');
        }
    }



    render() {
        // Render appointment list if active.
        // playerName and playerAvatar values are need to invoke client.

        const renderEmailClient = () => {
            
            if (this.state.showEmailClient) {
                const player = new Player();
                player.email = this.state.userEmail;
                player.name = '';
                player.avatar = '';
                const initialEmailSubject = this.subject;
                const configurationLocation = '/config/config_'+"1"+'.json';
                //console.log(configurationLocation)
                return <EmailClient player      = {player}
                    initialEmailSubject         = {initialEmailSubject}
                    configurationLocation       = {configurationLocation}
                    botEmailResponse            = {this.handleBotEmailResponse}
                    currentEpisode              = {this.currentEpisodeIndex}
                />;
            }
            return null;
        };



        return (
          <Div100vh  dontresize="true" className="h-full w-full flex flex-col inbox">
                <div className="modalcontainer inboxcontainer w-full h-full">
                    <div className="header">

                        <h2>Contact</h2>< button className="close" onClick={this.closeModal}/>  
                    </div>
                    <div className="  w-full h-full HAKEmailChatContainer">
                     {this.state.userEmail  !== undefined && (renderEmailClient())}
                     {this.state.userEmail  === undefined && (<EnterEmailView closeCallback={this.closeEmailPopUp.bind(this)}/>)}
                    </div>

                 </div>


            </Div100vh>
        )
    }
}
