import React from 'react'
import ReactPlayer from 'react-player'

//import {sendKlaviyoEvent} from '../utils/klaviyo.js';

export default function VideoViewer(props) {


	const onEndedVideo = (e) => {
		console.log("onEndedVideo")

		//sendKlaviyoEvent("BW0"+this.currentSeasonIndex+"-complete",this.state.userEmail);

		props.setTimerLogout()
	}
	

	

    return (<div className="videoViewer">
    	<ReactPlayer url={props.video_url} controls={true} playing={true} onEnded={onEndedVideo}/>
    	</div>)
    
}
