var icons = [{
          "id": 0,
          "name": "Good Fences",
          "icon": "s1/folder-good-fence.svg",
          "top": 50,
          "left": 40,
          "episode": 1,
          "season":1,
          "type":"folder",
          "parent":-1,
        },
         {
          "id": 1,
          "name": "Photos",
          "icon": "s1/folder-photos.svg",
          "top": 440,
          "left": 40,
          "episode": 1,
          "season":1,
           "type":"folder",
          "parent":-1,
        },
        {
          "id": 2,
          "name": "screenshot_001.jpg",
          "icon": "s1/icon-image-screenshot-001.svg",
          "top": 570,
          "left": 40,
          "episode": 1,
          "season":1,
          "type":"image",
          "parent":0,
          "imageURL":"s1/screenshot_001.jpg"
        },
        {
          "id": 3,
          "name": "screenshot_002.jpg",
          "icon": "s1/icon-image-screenshot-002.svg",
          "top": 570,
          "left": 40,
          "episode": 2,
          "season":1,
          "type":"image",
          "parent":0,
          "imageURL":"s1/screenshot_002.jpg"
        },
        {
          "id": 4,
          "name": "screenshot_003.jpg",
          "icon": "s1/icon-image-screenshot-003.svg",
          "top": 570,
          "left": 40,
          "episode": 3,
          "season":1,
          "type":"image",
          "parent":0,
          "imageURL":"s1/screenshot_003.jpg"
        },
        {
          "id": 5,
          "name": "screenshot_004.jpg",
          "icon": "s1/icon-image-screenshot-004.svg",
          "top": 570,
          "left": 40,
          "episode": 4,
          "season":1,
          "type":"image",
          "parent":0,
          "imageURL":"s1/screenshot_004.jpg"
        },
        {
          "id": 6,
          "name": "screenshot_005.jpg",
          "icon": "s1/icon-image-screenshot-005.svg",
          "top": 570,
          "left": 40,
          "episode": 5,
          "season":1,
          "type":"image",
          "parent":0,
          "imageURL":"s1/screenshot_005.jpg"
        },
        {
          "id": 7,
          "name": "image_001.jpg",
          "icon": "s1/icon-image-001.svg",
          "top": 570,
          "left": 40,
          "episode": 1,
          "season":1,
          "type":"image",
          "parent":1,
          "imageURL":"s1/images_001.jpg"
        },
        {
          "id": 8,
          "name": "image_002.jpg",
          "icon": "s1/icon-image-002.svg",
          "top": 570,
          "left": 40,
          "episode": 3,
          "season":1,
          "type":"image",
          "parent":1,
          "imageURL":"s1/images_002.jpg"
        },
        {
          "id": 9,
          "name": "Locked Video File",
          "icon": "s1/icon-locked-video.svg",
          "top": 570,
          "left": 40,
          "episode": 6,
          "season":1,
          "type":"lockedVideoBWs1",
          "parent":-1,
          "passwordprotected":true,
          "password":"Me",
          "videoURL":"s1/hello.mp4"
        }

        

    ]




export default icons = icons;