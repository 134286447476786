import { Question  } from '../models/question';

/**
 * Bot service
 *
 * Overview: Provides Bot related services
 *
 */

export class BotService {
    constructor() {
    }

    /**
     * Calls bot API to ask question.
     * @param botKnowledgeBaseId API knowledgeBase Id
     * @param botAuthorizationEndpointKey API authorization key
     * @param question Model housing bot question API model
     * @returns Status 200 if returned
     */
    //@ts-ignore
    askQuestion(endpointRoot: string, botKnowledgeBaseId: string, botAuthorizationEndpointKey: string, question: Question) {
        return new Promise((resolve, reject) => {
            fetch('https://api.huntakiller.com/qna/bw1', {
                method: 'POST',
                body: JSON.stringify(question),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': botAuthorizationEndpointKey
                }
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            });
        });
    }
}
