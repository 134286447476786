import React, { useState, useEffect } from 'react';
import store from 'store';
import Div100vh from 'react-div-100vh';
import { useHistory } from 'react-router-dom';

import PASSWORDS from '../datas/passwords.js';

export default function LoginEpisodeView() {
  
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [errorPw, setErrorPw] = useState(false);


  useEffect(() => { 
    //console.log("useEffect LoginEpisodeView")
    const passwordStorage = store.get("passwordBW")
  
    if (!(typeof passwordStorage === 'undefined') && !(typeof PASSWORDS[passwordStorage])) {
     history.push("Desktop")
      
    }

  }, [history])

  const handleChange = (event) => {
    setPassword(event.target.value)
    setErrorPw(false)
    
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      //console.log('enter press here! ')
      document.activeElement.blur();
      event.preventDefault();
    }
  }


  const handleSubmit = (event) => {

    event.preventDefault();
    document.getElementById("password").blur();
    
    

    if(PASSWORDS[password.toUpperCase()]){
      store.set('passwordBW', password.toUpperCase());
      history.push("Desktop")
    
    }else{
      setErrorPw(true)
    }

  }

  

  return (
   <Div100vh  dontresize="true"  className="h-full w-full flex justify-center items-center z-10 seasonDesktop">

          <div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
           
            
            

            <form className=" rounded  pt-6 " onSubmit={handleSubmit}>
              <div className="">

                    <div className=" mb-3">
                  <input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={password}
              onChange={handleChange} type="password" autoFocus id="password" placeholder="Password" onKeyPress={handleKeyPress}/>
                      <p className="error mt-2">{errorPw && "Invalid Password"} &nbsp;</p>
                    </div>
              </div>
              <div className="flex items-center justify-between">
              <button className="bg-yellow mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={false}>
                ENTER
              </button>
             
            </div>
            </form>

          </div>
        </Div100vh>
  );
}